exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-service-exterior-wrought-iron-decorative-fencing-js": () => import("./../../../src/pages/service/exterior-wrought-iron-decorative-fencing.js" /* webpackChunkName: "component---src-pages-service-exterior-wrought-iron-decorative-fencing-js" */),
  "component---src-pages-service-exterior-wrought-iron-decorative-roof-rail-js": () => import("./../../../src/pages/service/exterior-wrought-iron-decorative-roof-rail.js" /* webpackChunkName: "component---src-pages-service-exterior-wrought-iron-decorative-roof-rail-js" */),
  "component---src-pages-service-exterior-wrought-iron-gates-js": () => import("./../../../src/pages/service/exterior-wrought-iron-gates.js" /* webpackChunkName: "component---src-pages-service-exterior-wrought-iron-gates-js" */),
  "component---src-pages-service-exterior-wrought-iron-railings-js": () => import("./../../../src/pages/service/exterior-wrought-iron-railings.js" /* webpackChunkName: "component---src-pages-service-exterior-wrought-iron-railings-js" */),
  "component---src-pages-service-exterior-wrought-iron-window-rail-js": () => import("./../../../src/pages/service/exterior-wrought-iron-window-rail.js" /* webpackChunkName: "component---src-pages-service-exterior-wrought-iron-window-rail-js" */),
  "component---src-pages-service-interior-glass-railings-js": () => import("./../../../src/pages/service/interior-glass-railings.js" /* webpackChunkName: "component---src-pages-service-interior-glass-railings-js" */),
  "component---src-pages-service-interior-wood-railings-js": () => import("./../../../src/pages/service/interior-wood-railings.js" /* webpackChunkName: "component---src-pages-service-interior-wood-railings-js" */),
  "component---src-pages-service-interior-wrought-iron-railings-js": () => import("./../../../src/pages/service/interior-wrought-iron-railings.js" /* webpackChunkName: "component---src-pages-service-interior-wrought-iron-railings-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-city-template-js": () => import("./../../../src/templates/city-template.js" /* webpackChunkName: "component---src-templates-city-template-js" */)
}

